.content-section {
  @apply px-6 py-12 md:py-36;
}

.dark-section {
  @apply bg-zinc-900 text-white;
}

.white-section {
  @apply bg-white;
}

p,
li {
  @apply font-serif;
}
