@keyframes scale-hover {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1.01);
  }
}

.hover-scale:hover {
  animation: scale-hover 0.3s;
  transform: scale(1.01);
}

.hover-scale {
  transition: transform 0.2s;
}
